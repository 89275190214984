/*
Theme Name: Rivers McNamara Theme
Theme URI: http://creativepickle.com/
Description: Custom Theme for Rivers-McNamara
Author: CreativePickle
Version: 1.0
Tags: 
*/

@import url('reset.css');
@import (less) "mixins.less";

/****LAYOUT****/
body > #container {
     flex: 1; /* 2 */
  width: 100%;
  position:relative;
  &:after{
      content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0px;
    visibility: hidden;
      }
    }

body{
    background:#fff;
    display: flex;
  flex-direction: column;
  height: 100vh; /* 1, 3 */
    &.page-template-homepage.php{
        
    }
}

.central{
    width:100%;
    max-width:@centralWidth!important;
    margin-left: auto;
    margin-right:auto;
    padding:0 2% 0 2%!important;
    position:relative;
    }


#main {
    width:@mainWidth;
	padding:0;
	margin:0px auto;
    }
    
#content{
    width:@mainWidth;
	padding:0px 0 30px 0;
	.bodyfont();
	&.no-pad{
	    padding:0;
	    }
	}
	
#content-wrap{
   
    }

#main-content{
	padding:10px 0 40px 0!important;
    line-height:1.8;
    a{
    	color:@red;
    	&:hover{
    		color:@redDark;
    		}
    	}
    .left{padding-right:40px;}
    .content-section{
    	margin-bottom:30px;
    	strong{
    		.uppercase();
    		.poppins(16px);
    		color:@red;
    		.bold();
    		}
    	}
    ul{
        padding:10px 0 10px 0;
        li{
        	padding-left:25px;
        	position: relative;
            .fa-circle{font-size:6px;position: absolute;top:8px;left:0;}
            padding:0 0 0 20px;
            margin-bottom:8px;
            }
            
        }
 
    img{
        border:2px solid @beige;
        }
    &.bottom-border{
    	border-bottom: 2px solid @beige;
    	margin-bottom:10px;
    	}
    h3{
    	.poppins(36px);
    	.bold();
    	.uppercase();
    	color:@red;
    	margin:10px 0 20px 0;
    	line-height: 1.2;
    	letter-spacing: .10em;
    	}
    h2{
    	.oxygen(36px);
    	color:@beigeDark;
    	.uppercase();
    	margin:10px 0 20px 0;
    	letter-spacing: .1em;
    	line-height: 1.2;
    	}
    h4{
    	.oxygen(30px);
    	color:@redDark;
    	margin:10px 0 20px 0;
    	line-height: 1.2;
    	letter-spacing: .1em;
    	}
    table{
     	width:100%;
    	border-collapse: collapse;
    	tr{
    		&:nth-child(odd){background:@beigeLight;}
    		}
    	td{
    		border:1px solid @beigeDark;
    		padding:10px;
    		
    		}
     	}
    }
	
#sidebar{
	padding:10px 0 0 40px;
	border-left:2px solid @beige;
	}
	
ul.icon-list{
	li{
		position: relative;
		padding-left:30px!important;
		background-size:20px auto!important;
		margin-bottom:15px!important;
		.fa-circle{display:none!important;}
		&.resume{
			background:url(images/icons/resume.svg) no-repeat 0px 2px;
			}
		&.vcard{
			background:url(images/icons/vcard.svg) no-repeat 0px 2px;
			}
		&.address{
			background:url(images/icons/location.svg) no-repeat 0px 2px;
			}
		&.phone{
			background:url(images/icons/phone.svg) no-repeat 0px 2px;
			}
		&.fax{
			background:url(images/icons/fax.svg) no-repeat 0px 2px;
			}
		&.email{
			background:url(images/icons/email.svg) no-repeat 0px 2px;
			}
		}
	}
	
.accordion-wrap{
	margin-top:25px;
	}


	
#main-content ul.subpage-menu{
    width:100%;
    padding:0;
    margin:0 0 40px 0;
    >li{
        display:block;
        margin:8px 0 8px 0!important;
        padding:0;
        .fa-circle{display: none!important;}
        >a{
        	color: @beigeDark;
        	.centered();
            display:block;
            padding:10px;
            border: 2px solid @beige;
            .transition(all linear 200ms);
            font-size:30px;
            .scale(1);
            &:hover{
                background:#f1f1f1;
                .scale(1.02);
                background:@red;
                border:2px solid @red;
                color:#fff;
                }
            }
            &.current_page_item>a, &.current_page_ancestor>a{
                background:#f1f1f1;
                padding-left:15px;
                }
            
            >ul.children{display:none;}
            &.current_page_item>ul.children, &.current_page_ancestor>ul.children{
                padding:0;
                margin:0;
                display:block;
                >li{
                    >a{
                        .italic();
                        padding:10px;
                        border-top: 2px solid #e6e6e6;
                        .transition(all linear 130ms);
                        font-size:16px;
                        .text-shadow();
                        display:block;
                        &:hover{color:@accent;}
                        }
                         &:first-child>a{border-top:none;}
                         &.current_page_item>a{color:@accent;}
                    }
                }
        }
    }

ul.document-list{
     padding:0!important;
     margin:0px 0 10px 0!important;
    li{
        background:none!important;
        padding-left:0!important;
        margin-bottom:0!important;
        a{
            display:block;
            padding:10px 0 10px 0;
            .bodyfont();
            .uppercase();
            font-weight:300;
            border-bottom:1px solid @content;
            .transition();
            i{
                .inline-block();
                font-size:20px;
                color:@content;
                margin-right:10px;
                position:relative;
                top:2px;
                }
            &:hover{
                color:@content!important;
                background:@content;
                padding-left:10px;
                }
            }
        }
    }

#footer {
	position: relative;
	width:@mainWidth;
	clear:both;} 
	
.footer-content{
	text-align:center;
	position: relative;
	padding:0;
	#footer-map{
		padding: 0!important;
		.acf-map{
			height:450px;
			margin:0;
			padding:0;
			}
		}
	#footer-contact{
		padding:60px 20px 10px 20px;
		.oxygen(16px);
		img{
			.inline-block();
			width: 80%;
			max-width:450px;
			}
		ul.icon-list{
			margin-top:40px;
			.inline-block();
			max-width:300px;
			li{
					text-align: left;
				width:100%;
				max-width:500px;
				margin-bottom: 30px;
				}
			}
		}
	}
	
ul.contact-details{
	li{
		position: relative;
		padding-left:50px;
		i{
			position: absolute;
			font-size:22px;
			color:@aqua;
			top:0;
			left:0;
			
			}
		}
	}
	
#copyright{
	color:@content;
	.uppercase();
	background:@beige;
	padding:10px;
	.centered();
	.poppins(12px);
	letter-spacing: .22em;
	font-weight: 300;
	a{
		color:@content;
		&:hover{
			text-decoration: underline;
			}
		}
	}

.stripe{
	height:2px;
	width: 100%;
	position: absolute;
	background:@beige;
	z-index: 200;
	&.top{
		top:10px;
		left:0;
		}
	&.bottom{
		bottom:10px;
		left:0;
		
		}
	}	
.section{
	.oxygen(16px);
	line-height: 1.5;
    width:100%;
    position:relative;
    min-height:200px;
    padding:20px 0 20px 0;
    &.bordered{
    	border-top:2px solid @beige;
    	border-bottom:2px solid @beige;
    	margin:10px 0 10px 0;
    	}
    &.border-top{
    	border-top:2px solid @beige;
    	margin-top:10px;
    	}
    &.gray-section{
    	background:@beige;
    	}
    }
    
h5.section-head{
	color:inherit;
	.poppins(45px);
	.uppercase();
	margin-bottom: 30px;
	letter-spacing: .05em;
	&.small{
		font-size: 24px;
		font-weight: 600;
		}
	&.medium{
		font-size: 30px;
		font-weight: 600;
		}
	&.aqua{color:@red;}
	}
    
.vert-center-wrap{
    display:table;
    height:100%;
    height: inherit;
    >.vert-center{
        display:table-cell;
        vertical-align:middle;
        
        }
    }
	
/**********PAGE STYLES**********/

.callout{
    display:block;
    .uppercase();
    position:relative;
    padding:0 60px 0 60px;
    .centered();
    .poppins(28px);
    .uppercase();
    color:@red;
    line-height: 1.5;
    .quote{
        display:none;
        width:50px;
        height:50px;
        position:absolute;
        &.open{
            top:0;
            left:0;
            background:url(images/open-quote-callout.png) no-repeat 0 0;
            }
        &.close{
            bottom:0;
            right:0;
            background:url(images/close-quote-callout.png) no-repeat 0 0;
            }    
        }
    &.left{
        width:100%;
        max-width:340px;
        float:left;
        margin:0 40px 30px 0;
        padding:0 20px 0 0px;
        border-right:2px solid @beigeDark;
        }
    &.right{
        width:100%;
        max-width:340px;
        float:right;
        margin:0 0px 30px 40px;
        padding:0 0 0 20px;
        border-left:2px solid @beigeDark;
        }
    &.center{
        width:100%;
        border-top:1px solid #d9d8d3;
        border-bottom:1px solid #d9d8d3;
        padding:15px 20px 15px 20px;
        margin:10px 0 30px 0;
        .quote{
            display:none!important;
            }
        }
     }

.image-scaler{
    .scaler();
    min-width:100px;
    height:auto;
    }

/***********HEADER and NAVIGATION*********/
@menuLink: #666;
@menuLinkHover: #333;

#main-header{
    background:rgba(228, 228, 219, 1.000);
    border-top:3px solid @beigeDark;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    z-index: 9999;
    padding:45px 0 30px 0;
    .transition();
    &.active{
    	background:rgba(255,255,255,1);
    	.box-shadow-custom(0px,3px,3px,rgba(0,0,0,0.3));
    	}
    &.subpage{
    	border-bottom:5px solid #fff;
    	}
}

#nav{
    
}

#menu-main-navigation-1{
    list-style:none;
    padding:0;
    text-align: right;
    >li{
        .inline-block();
        margin:0 10px 0 10px;
        position:relative;
        &:hover>a, &.current_page_item>a, &.current-page-ancestor>a{color:@red!important;border-top:2px solid @red;
            border-bottom:2px solid @red;}
      
        &:last-child{margin-right:0;}
        >a{
            .poppins(15px);
            letter-spacing:.02em;
            display:block;
            padding:5px 0 5px 0;
            color:@content;
            font-weight: 600;
            .uppercase();
            .transition();
            .scale(1);
            border-top:2px solid transparent;
            border-bottom:2px solid transparent;
            &:hover{
            color:@red!important;
            border-top:2px solid @red;
            border-bottom:2px solid @red;
            .scale(1.05);
            }
            }
        
        }
    }
 
ul#menu-social-navigation{
    .inline-block();
    margin:0;
    padding:0;
    li{
        .inline-block();
        color:#fff;
        font-size:30px;
        padding:0;
        margin:0;
        position:relative;
        margin:0 3px 0 3px;
        &:hover{color:@accent;}
        a{
            display:block;
            text-align:left;
            text-indent:-9999px;
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            }
        }
    }   	
/***********HEADER STYLES*******/

h1{
    color:#333;
    font-size:36px;
    padding:0 0 12px 0;
    }
    
h2{
    font-size:30px;
    color:#333;
    padding:0 0 12px 0;
    }
    
h3{
    font-size:24px;
    color:#333;
    padding:0 0 8px 0;
    }
    
h4{
    font-size:18px;
    padding:0 0 8px 0;
    }
    
/***********BUTTONS***********/
a.block{
	position: absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	z-index: 200;
	}

a.button, button.submit{
    .bodyfont();
    display:inline-block;
    background:@red;
    padding:5px 20px 5px 20px;
    color:#fff!important;
    position:relative;
    .rounded-corners(4px);
    .centered();
    .poppins(18px);
    .uppercase();
    font-weight:300;
    letter-spacing: .08em;
    outline:2px solid #fff;
    outline-offset:5px;
    .transition();
    margin-top:15px;
    .text-shadow-custom(0px,3px,0px,rgba(0,0,0,0.1));
    i,svg{margin-left:6px;}
    &:hover{
        background:@redDark;
        color:#fff!important;
        .text-shadow-custom(0px,3px,0px,rgba(0,0,0,0.1));
        }
    
    }


/*********HOMEPAGE**********/
span.diamond{
			.inline-block();
			vertical-align: middle;
			height:20px;
			width:20px;
			background:url(images/diamond.svg) no-repeat center center;
			margin:0 15px 0 15px;
			position: relative;
			top:-5px;
			}

#banner{
    border-bottom:1px solid #fff;
    position:relative;
	padding:240px 10px 100px 10px;
	border-bottom: 3px solid #fff;
	h1{
		.poppins(50px);
		font-weight:600;
		color:#000;
		.uppercase();
		letter-spacing:.07em;
		
		}
    &.subpage{
    	.centered();
    	background:#fff;
    	padding:200px 20px 40px 20px;
    	margin-bottom:20px;
    	h1{
    		color:#000;
    		}
    	}
    &.attorney{
        padding:240px 0 30px 0;
        margin-bottom:45px;
    	background:@red;
    	min-height: 560px;
        border-bottom:1px solid #fff;
        .scaler();
        .title{
        	padding:0 30px 0 16%;
        	width:70%;
	        height:100%;
	        position: absolute;
	        right:0;
	        top:0;
        	background: rgba(228,226,218,0);
			background: -moz-linear-gradient(left, rgba(228,226,218,0) 0%, rgba(228,226,218,0.80) 25%, rgba(228,226,218,0.9) 100%);
			background: -webkit-gradient(left top, right top, color-stop(0%, rgba(228,226,218,0)), color-stop(25%, rgba(228,226,218,0.80)), color-stop(100%, rgba(228,226,218,0.9)));
			background: -webkit-linear-gradient(left, rgba(228,226,218,0) 0%, rgba(228,226,218,0.80) 25%, rgba(228,226,218,0.9) 100%);
			background: -o-linear-gradient(left, rgba(228,226,218,0) 0%, rgba(228,226,218,0.80) 25%, rgba(228,226,218,0.9) 100%);
			background: -ms-linear-gradient(left, rgba(228,226,218,0) 0%, rgba(228,226,218,0.80) 25%, rgba(228,226,218,0.9) 100%);
			background: linear-gradient(to right, rgba(228,226,218,0) 0%, rgba(228,226,218,0.80) 25%, rgba(228,226,218,0.9) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b6bfbf', endColorstr='#b6bfbf', GradientType=1 );
	        h1{
	        color:#000;
	        .uppercase();
	        .bold();
	        .poppins(45px);
	        .bold();
	        line-height: 1.2;
			
	        }
	        span.subtitle{
	        	display: block;
	        	.poppins(24px);
	        	font-weight:300;
	        	}
	        >div{
				position: relative;
				top:60%;
				
				}
    		}
        }
    }
    
#about-section{
	padding:155px 0 0 0;
	border-bottom: 3px solid #fff;
	.content{
		width:50%;
		height:100%;
		position: relative;
		float:right;
		background:rgba(255,255,255,0.7);
		padding:35px;
		h5{
			.poppins(45px);
			font-weight:300;
			margin-bottom:30px;
			line-height: 1.2;
			span.uppercase{
				font-weight:600;
				}
			}
		}
	}
	
#testimonials-section{
	padding:150px 0 150px 0;
	border-top:3px solid @beige;
	background:#fff url(images/watermark.svg) no-repeat right center;
	background-size:70% auto;
	}


	
.testimonial-slider-item{
	.poppins(26px);
	color:@content;
	font-weight: 300;
	outline: none;
	padding:20px 120px 20px 120px;
	span.quote{
		position: absolute;
		color:@beigeDark;
		font-family: 'Cardo', serif;
		font-size:140px;
		.text-shadow-custom(0px,3px,0px,#e5e5e5);
		vertical-align: top;
		&.open{
			top:-30px;
			left:30px;
			}
		&.close{
			top:-30px;
			right:30px;
			}
		}
	&.page{
		font-size:22px;
		padding:20px 90px 20px 90px;
		span.quote{
			font-size:110px;
			&.close{
			top:auto;
			bottom:-60px;
			right:30px;
			}
			}
		}
	}
    
ul.slick-dots{
    width:100%;
    .centered();
    position:absolute;
    bottom:-100px;
    z-index:50;
    li{
        .inline-block();
        padding:0;
        margin:0 3px 0 3px;
        max-width:20px;
        svg{
       	width:100%;
       	height:auto;
       	.diamond-slides{visibility: hidden;}
        }
        &:hover{
        	cursor: pointer;
        	}
        &.slick-active{
            button{
                background:#fff!important;
                }
            }
        }
    }
    
#practice-section{
	background:@beige;
	color:@content;
	.poppins(22px);
	font-weight: 300;
	padding:80px 0 80px 0;
	}

#practice-grid-wrap{
	margin-top:30px;
>.practice-item{
	height:250px;
	position: relative;
	border-left:1px solid @beigeDark;
	border-right:1px solid @beigeDark;
	color: @content;
	.title{
		position: relative;
		padding:50px 30px 10px 30px;
		svg{
			.inline-block();
			width:50px;
			height:auto;
			.dmnd-2{
				fill:@beigeDark;
				}
			.dmnd-3{
				stroke:@beigeDark;
				}
			}
		
		}
	h6{
			.poppins(26px);
			.uppercase();
			line-height:1.3;
			margin-top:15px;
			letter-spacing: .10em;
			font-weight: 300;
			}
	.fader{
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height:100%;
		background:#fff;
		color:@content;
		opacity:0;
		
		z-index: 50;
		h6{color:@red;margin-bottom:10px;}
		.text{
			opacity: 0;
			transform: translateY(20px);
			padding:40px 20px 10px 20px;
			.oxygen(16px);
			line-height: 1.4;
			s
			h6{
				font-size:18px;
				color:@red;
				}
			}
		}
	&:nth-child(-n+3){
		border-bottom:1px solid @beigeDark;
		}
	&:nth-child(n+4){
		border-top:1px solid @beigeDark;
		}
	}
}

.banner-content{
    height:100%;
    >div{
        
        height:100%;
        background:rgba(255,255,255,0.7);
        padding:20px;
        position:relative;
       
        .bold();
        color:@accent;
        >div{
            position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
        }
    h5{
        .uppercase();
        .bold();
        color:@accent;
        line-height:1!important;
        margin-bottom:20px;
        }
    
    }
    
/*********ATTORNEYS*********/

img.watermark{
	position: absolute;
	border:none!important;
	right:0;
	width:50%;
	height:auto;
	top:50%;
	transform:translateY(-50%);
	opacity:0.6;
	}

#publications{
	background:@beige;
	position: relative;
	overflow: hidden;
	background-size:40% auto;
	color:@content;
	padding:80px 0 60px 0;
	h5.section-head{
		.text-shadow();
		}
	ul{
        padding:10px 0 10px 0;
        li{
        	padding-left:25px;
        	position: relative;
            .fa-circle{font-size:6px;position: absolute;top:8px;left:0;}
            padding:0 0 0 20px;
            margin-bottom:15px;
            }
        }
	}

.section-trigger{
	padding-bottom: 0!important;
	margin-bottom: 0!important;
	line-height: 1.2!important;
	.scale(1);
	.transition();
	span.icon{
		.inline-block();
		width:22px;
		height:32px;
		background:url(images/bullet-red.svg) no-repeat center center;
		margin:0 10px 0 10px;
		}
	&:hover{
		.scale(1.1);
		cursor: pointer;
		}
	}

.section-toggle-container{
	overflow:hidden;
		background:transparent;
		height:0;
		.block{
			opacity:0;
			padding:25px;
			}
	}

.toggler{
	margin-bottom:0;
	border-top:2px solid @beigeDark;
	.trigger{
		background:transparent;
		.transition();
		position:relative;
		padding:25px 40px 25px 25px;
		.transition();
		span.icon{
			.inline-block();
			position: absolute;
			right:15px;
			top:50%;
			margin-top:-12px;
			width:18px;
			height:24px;
			background:url(images/bullet-aqua.svg) no-repeat center center;
			}
		&:hover, &.active{
			cursor:pointer;
			background:@grayMed;
			}
		&.active{
			
			}
		
		h5{
			color:#000;
			.poppins(20px);
			.bold();
			letter-spacing:.05em;
			line-height:1.3;
			}
		}
	.toggle_container{
		overflow:hidden;
		background:@beigeDark;
		height:0;
		.block{
			opacity:0;
			padding:0px 25px 25px 25px;
			ul{
        padding:10px 0 10px 0;
        li{
        	padding-left:25px;
        	position: relative;
            .fa-circle{font-size:6px;position: absolute;top:8px;left:0;}
            padding:0 0 0 20px;
            margin-bottom:8px;
            }
        }
			}
		}
	}
	
.gallery-item{
	.inline-block();
	width:90%;
	max-width:300px;
	margin:20px;
	h6{
		.oxygen(18px);
		font-weight:300;
		color:@grayMed;
		letter-spacing: .10em;
		.uppercase();
		margin-top:15px;
		}
	}
	
#awards, #memberships, #community{
	padding:60px 0 60px 0;
	}
	
/*********PRACTICE AREAS********/

.practice-item.page{
	.centered();
	padding:100px 0 40px 0;
	margin-bottom: 40px;
	border-bottom:1px solid @beige;
	background:url(images/diamond-circle.svg) no-repeat top center;
	background-size:auto 80px;
	h5{
		.oxygen(35px);
		.uppercase();
		letter-spacing: .10em;
		margin-bottom: 20px;
		color:@redDark;
		}
	}
    

/**********ITEMS and WIDGETS**************/

.item{
    width:100%;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:2px solid @beige;
    h5{
    	
    	}
}

.widget{
    margin-bottom:30px;
    padding-bottom: 30px;
    border-bottom:2px solid @beige;
    h2.widget-title{
        
    }
}
 

/********ALIGNMENT********/

/* Alignment */
.alignleft {
    display: inline;
	float: left;
	margin-right: 1.625em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.625em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft,
img.alignright,
img.aligncenter {
    margin-bottom: 1.625em;
}	

.clear{
    clear:both;
    }  

.wp-caption-text{
    font-size:12px;
    .italic();
    }
    
figure{
    -webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0;
-webkit-margin-end: 0;
    }
    
.fixed-bg{
    background-attachment:fixed!important;
    }
    
.scaler{
    position:relative;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    -o-background-size: cover!important;
    background-size: cover!important;
}

.centered{
    text-align:center;
    }